import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ContactForm from "../ContactForm";

const LandingContact = ({ setError }) => {
  const [waitlistModalShow, setWaitlistModalShow] = useState(false);

  return (
    <div>
      <Container fluid className="py-5 text-dark bg-light min-vh-100">
        <Row className="align-items-center mt-5">
          <Col xs={{ span: 10, offset: 1 }} className="text-start">
            <Row className="my-5 pt-5">
              <Col className="display-6">
                <AnimationOnScroll duration={2} animateOnce={true} animateIn="animate__fadeIn">
                  Contact
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col xs={{ span: 8, offset: 2 }} sm={{ span: 6, offset: 4 }} className=" text-end lead">
            <Row className="my-5">
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={250} animateIn="animate__fadeIn">
                  You can reach us at info@metamoda.co, or by clicking the button below.
                </AnimationOnScroll>
              </Col>
            </Row>
            <Row className="my-5">
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={500} animateIn="animate__fadeIn">
                  We will make every effort to respond to you as soon as possible.
                </AnimationOnScroll>
              </Col>
            </Row>

            <Row className="mb-5">
              <Col xs={{ span: 6, offset: 3 }} sm={{ span: 6, offset: 6 }} className="align-items-end text-end">
                <AnimationOnScroll duration={2} animateOnce={true} delay={750} animateIn="animate__fadeIn">
                  <Button onClick={() => setWaitlistModalShow(true)} size="lg" className="my-3" variant="outline-dark">
                    Contact
                  </Button>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <ContactForm show={waitlistModalShow} onHide={() => setWaitlistModalShow(false)} setError={setError} />
    </div>
  );
};

export default LandingContact;
