import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      value
      isAdmin
    }
  }
`;
export const NEW_CONTACT = gql`
  mutation newContact($name: String!, $company: String, $email: String!, $type: String!, $country: String!, $message: String!, $entity: String!) {
    addContact(name: $name, company: $company, email: $email, country: $country, type: $type, message: $message, entity: $entity) {
      id
    }
  }
`;
