import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { setContext } from "@apollo/client/link/context";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

const authLink = setContext((_, { headers }) => {
  const getTokenWithExpiry = () => {
    const itemStr = localStorage.getItem("stf-user-token");
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    if (Date.now() > item.expiry) {
      localStorage.clear();
      return null;
    }
    return item.value;
  };

  const token = getTokenWithExpiry();
  return {
    headers: {
      ...headers,
      authorization: token ? `bearer ${token}` : null,
    },
  };
});

const httpLink = createUploadLink({ uri: "https://eo4hdgy324.execute-api.me-south-1.amazonaws.com/" });

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
