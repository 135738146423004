import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ContactForm from "../ContactForm";

const LandingAbout = ({ setError }) => {
  const [waitlistModalShow, setWaitlistModalShow] = useState(false);

  return (
    <div>
      <Container fluid className="py-5 text-dark bg-light min-vh-100">
        <Row className="align-items-center mt-5">
          <Col xs={{ span: 10, offset: 1 }} className="text-start">
            <Row className="my-5 pt-5">
              <Col className="display-6">
                <AnimationOnScroll duration={2} animateOnce={true} animateIn="animate__fadeIn">
                  About
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={{ span: 8, offset: 2 }} sm={{ span: 6, offset: 5 }} className=" text-end lead">
            <Row className="my-5">
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={250} animateIn="animate__fadeIn">
                  We believe that fashion starts with fabric.
                </AnimationOnScroll>
              </Col>
            </Row>
            <Row className="my-5">
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={500} animateIn="animate__fadeIn">
                  We combine decades of experience and a fashion forward approach to develop qualities and designs that inspire fashion and respect the earth.
                </AnimationOnScroll>
              </Col>
            </Row>
            <Row className="my-5">
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={750} animateIn="animate__fadeIn">
                  Our collection spans the fashion spectrum and we believe in giving designers freedom through choice.
                </AnimationOnScroll>
              </Col>
            </Row>
            <AnimationOnScroll duration={2} animateOnce={true} delay={1000} animateIn="animate__fadeIn">
              <Row className="mt-5 mb-2 pt-5">
                <Col className="fw-bold">Fashion | Forward</Col>
              </Row>
              <Row className="mt-1 mb-1 pt-5">
                <Col className="small">To learn more about our brands and how we can collaborate, please contact us.</Col>
              </Row>

              <Row className="mb-5">
                <Col xs={{ span: 6, offset: 3 }} sm={{ span: 6, offset: 6 }} className="align-items-end">
                  <Button onClick={() => setWaitlistModalShow(true)} size="lg" className="my-3" variant="outline-dark">
                    Contact
                  </Button>
                </Col>
              </Row>
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>

      <ContactForm show={waitlistModalShow} onHide={() => setWaitlistModalShow(false)} setError={setError} />
    </div>
  );
};

export default LandingAbout;
