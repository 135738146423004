import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { AnimationOnScroll } from "react-animation-on-scroll";
import CollectionModal from "./CollectionModal";
import ContactForm from "../../ContactForm";

const Collection = ({ setError }) => {
  const [waitlistModalShow, setWaitlistModalShow] = useState(false);
  const [collectionModalShow, setCollectionModalShow] = useState(false);
  const [sku, setSku] = useState("MET");

  return (
    <div>
      <Container fluid className="py-5 text-dark min-vh-100 bg-light">
        <Row className="align-items-center my-5 py-5">
          <Col xs={{ span: 10, offset: 1 }}>
            <Row className="text-start py-5 display-6">
              <Col>Collection</Col>
            </Row>
            <Row className="my-5">
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={500} animateIn="animate__fadeIn">
                  <Container
                    className="lead my-4 p-1"
                    onClick={() => {
                      setSku("MET");
                      setCollectionModalShow(true);
                    }}
                  >
                    <Row>
                      <Col>
                        <span className="fw-bold">Wool</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>5 Brands</Col>
                    </Row>
                    <Row>
                      <Col>20 Qualities</Col>
                    </Row>
                    <Row>
                      <Col>100+ Designs</Col>
                    </Row>
                    <Row>
                      <Col>500+ Colours</Col>
                    </Row>
                  </Container>
                </AnimationOnScroll>
              </Col>
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={600} animateIn="animate__fadeIn">
                  <Container
                    className="lead my-4 p-1"
                    onClick={() => {
                      setSku("GEC");
                      setCollectionModalShow(true);
                    }}
                  >
                    <Row>
                      <Col>
                        <span className="fw-bold">Shirting</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>2 Brands</Col>
                    </Row>
                    <Row>
                      <Col>14 Qualities</Col>
                    </Row>
                    <Row>
                      <Col>50+ Designs</Col>
                    </Row>
                    <Row>
                      <Col>200+ Colours</Col>
                    </Row>
                  </Container>
                </AnimationOnScroll>
              </Col>
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={700} animateIn="animate__fadeIn">
                  <Container
                    className="lead my-4 p-1"
                    onClick={() => {
                      setSku("AMA");
                      setCollectionModalShow(true);
                    }}
                  >
                    <Row>
                      <Col>
                        <span className="fw-bold">Chino</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>1 Brand</Col>
                    </Row>

                    <Row>
                      <Col>10 Qualities</Col>
                    </Row>
                    <Row>
                      <Col>150+ Colours</Col>
                    </Row>
                  </Container>
                </AnimationOnScroll>
              </Col>
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={800} animateIn="animate__fadeIn">
                  <Container
                    className="lead my-4 p-1"
                    onClick={() => {
                      setSku("JER");
                      setCollectionModalShow(true);
                    }}
                  >
                    <Row>
                      <Col>
                        <span className="fw-bold">Jersey</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>1 Brand</Col>
                    </Row>

                    <Row>
                      <Col>5 Qualities</Col>
                    </Row>
                    <Row>
                      <Col>100+ Colours</Col>
                    </Row>
                  </Container>
                </AnimationOnScroll>
              </Col>
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={900} animateIn="animate__fadeIn">
                  <Container
                    className="lead my-4 p-1"
                    onClick={() => {
                      setSku("550");
                      setCollectionModalShow(true);
                    }}
                  >
                    <Row>
                      <Col>
                        <span className="fw-bold">Spun</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>3 Brands</Col>
                    </Row>

                    <Row>
                      <Col>3 Qualities</Col>
                    </Row>
                    <Row>
                      <Col>20+ Colours</Col>
                    </Row>
                  </Container>
                </AnimationOnScroll>
              </Col>
            </Row>
            <Row className="w-100">
              <Col xs={{ span: 6, offset: 3 }} sm={{ span: 6, offset: 3 }} className="text-center align-items-center">
                <AnimationOnScroll duration={2} animateOnce={true} delay={1200} animateIn="animate__fadeIn">
                  <Button onClick={() => setWaitlistModalShow(true)} size="lg" className="my-3" variant="outline-dark">
                    Contact
                  </Button>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <ContactForm show={waitlistModalShow} onHide={() => setWaitlistModalShow(false)} setError={setError} />
      <CollectionModal show={collectionModalShow} onHide={() => setCollectionModalShow(false)} itemSku={sku} setError={setError} />
    </div>
  );
};

export default Collection;
