import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import LoginForm from "../LoginForm";

const LandingHeader = ({ setError, setToken, setIsAdmin }) => {
  const [loginModalShow, setLoginModalShow] = useState(false);

  let location = useLocation();
  let textColor = "text-dark";
  let bgColor = "navbar-light";

  if (location.pathname === "/") {
    textColor = "text-light";
    bgColor = "navbar-dark";
  }

  return (
    <div>
      <Navbar bg="none" expand="lg" className={`${bgColor} ${textColor} position-absolute top-0 end-0`} style={{ zIndex: 100000 }}>
        <Container className="px-2 mx-2">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto text-dark">
              <Nav.Link>
                <Link to="/contact" className="nav-link">
                  Contact
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/collection" className="nav-link">
                  Collection
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/about" className="nav-link">
                  About
                </Link>
              </Nav.Link>
              <Nav.Link onClick={() => setLoginModalShow(true)}>
                <Link className="nav-link" to="#">
                  Login
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <LoginForm show={loginModalShow} onHide={() => setLoginModalShow(false)} setError={setError} />
    </div>
  );
};

export default LandingHeader;
