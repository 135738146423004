import React from "react";
import Container from "react-bootstrap/Container";

const LandingVideo = ({ setError }) => {
  return (
    <div>
      <Container fluid className="position-absolute top-0 start-0 m-0 p-0 h-100">
        <img className="w-100 h-100" src="https://metamoda-public.s3.me-central-1.amazonaws.com/media/MMHero.JPG" id="metamodaHome" alt="" style={{ objectFit: "cover", zIndex: -1 }} />
      </Container>
    </div>
  );
};

export default LandingVideo;
