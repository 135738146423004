const CollectionList = () => {
  const collection = [
    {
      sku: "MET",
      title: "Wool",
      compositions: "50% WV to 100% WV",
      weights: "190g/m to 400g/m",
      yarns: "14.5µ up to 21µ",
      designs: "100+ Designs in Stock",
      colours: "500+ Colours in Stock",
      image: "https://metamoda-public.s3.me-central-1.amazonaws.com/media/METHero.JPG",
    },
    {
      sku: "GEC",
      title: "Shirting",
      compositions: "95% Cotton to 100% Cotton",
      weights: "86g/m to 300g/m",
      yarns: "60s up to 120s Double",
      designs: "50+ Designs in Stock",
      colours: "200+ Colours in Stock",
      image: "https://metamoda-public.s3.me-central-1.amazonaws.com/media/GECHero.JPG",
    },
    {
      sku: "AMA",
      title: "Chino",
      compositions: "50% Cotton / 50% Modal to 100% Cotton",
      weights: "150g/m to 250g/m",
      yarns: "60s up to 100s",
      designs: "Solid Colours Only",
      colours: "150+ Colours in Stock",
      image: "https://metamoda-public.s3.me-central-1.amazonaws.com/media/AMAHero.JPG",
    },
    {
      sku: "JER",
      title: "Jersey",
      compositions: "70% Cotton to 100% Cotton",
      weights: "190g/m to 400g/m",
      yarns: "60s up to 100s",
      designs: "Solid Colours Only",
      colours: "100+ Colours in Stock",
      image: "https://metamoda-public.s3.me-central-1.amazonaws.com/media/JERHero.JPG",
    },
    {
      sku: "550",
      title: "Spun Fabrics",
      compositions: "100% Spun Filament",
      weights: "100g/m to 120g/m",
      yarns: "Fine Spun Filament",
      designs: "Solid Colours Only",
      colours: "15 Colours Coming Soon",
      image: "https://metamoda-public.s3.me-central-1.amazonaws.com/media/550Hero.JPG",
    },
  ];

  return collection;
};
export default CollectionList;
