import React, { useState, useEffect } from "react";
import GuestHomePage from "./components/GuestHomePage";
import LandingHeader from "./components/landing/LandingHeader";
import LandingLogo from "./components/landing/LandingLogo";
import LegalDisclaimerModal from "./components/LegalDisclaimerModal";
import LandingAbout from "./components/landing/LandingAbout";
import LandingContact from "./components/landing/LandingContact";
import Legal from "./components/landing/Legal";
import Collection from "./components/landing/collection/Collection";

import Alert from "react-bootstrap/Alert";
import { useApolloClient } from "@apollo/client";
import { Routes, Route } from "react-router-dom";
import LandingFooter from "./components/landing/LandingFooter";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  const getTokenWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    if (Date.now() > item.expiry) {
      localStorage.clear();
      return null;
    }
    return item.value;
  };

  const storedToken = getTokenWithExpiry("stf-user-token");
  const storedAdmin = getTokenWithExpiry("stf-user-isAdmin");

  const [errorMessage, setErrorMessage] = useState(null);
  const [token, setToken] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const client = useApolloClient();

  const logout = () => {
    setToken(null);
    setIsAdmin(null);
    localStorage.clear();
    client.resetStore();
  };

  useEffect(() => {
    setToken(storedToken);
    setIsAdmin(storedAdmin);
    if (!storedToken || storedToken === "null") {
      logout();
    }
  }, [storedToken, storedAdmin]); // eslint-disable-line

  const notify = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  };

  if (!token) {
    return (
      <div>
        <Notify errorMessage={errorMessage} />
        <div>
          <div>
            <LandingHeader setError={notify} />
            <LandingLogo />
            <LegalDisclaimerModal />
          </div>
          <ScrollToTop>
            <Routes>
              <Route exact path="/" element={<GuestHomePage setError={notify} />} />
              <Route exact path="/legal" element={<Legal setError={notify} />} />
              <Route exact path="/collection" element={<Collection setError={notify} />} />
              <Route exact path="/about" element={<LandingAbout setError={notify} />} />
              <Route exact path="/contact" element={<LandingContact setError={notify} />} />
              <Route index element={<GuestHomePage setError={notify} />} />
              <Route path="*" element={<GuestHomePage setError={notify} />} />
            </Routes>
          </ScrollToTop>
          <div>
            <LandingFooter />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Notify errorMessage={errorMessage} />
      <div>
        <div>
          <LandingHeader setError={notify} />
          <LandingLogo />
          <LegalDisclaimerModal />
        </div>
        <ScrollToTop>
          <Routes>
            <Route exact path="/" element={<GuestHomePage setError={notify} />} />
            <Route exact path="/legal" element={<Legal setError={notify} />} />
            <Route exact path="/collection" element={<Collection setError={notify} />} />
            <Route exact path="/about" element={<LandingAbout setError={notify} />} />
            <Route exact path="/contact" element={<LandingContact setError={notify} />} />
            <Route index element={<GuestHomePage setError={notify} />} />
            <Route path="*" element={<GuestHomePage setError={notify} />} />
          </Routes>
        </ScrollToTop>
        <div>
          <LandingFooter />
        </div>
      </div>
    </div>
  );
};

const Notify = ({ errorMessage }) => {
  if (!errorMessage) {
    return null;
  }
  if (errorMessage === "Success!") {
    return <Alert variant="success">{errorMessage}</Alert>;
  }
  return <Alert variant="danger">{errorMessage}</Alert>;
};

export default App;
