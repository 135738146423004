import React from "react";
import Container from "react-bootstrap/Container";
import LandingVideo from "./landing/LandingVideo";

const GuestHomePage = ({ setError }) => {
  return (
    <div>
      <Container fluid className="m-0 p-0 bg-dark" style={{ overflow: "hidden" }}>
        <Container fluid className="min-vh-100 p-0 position-static">
          <LandingVideo />
        </Container>
      </Container>
    </div>
  );
};

export default GuestHomePage;
